import React, { useEffect, useState } from "react";
import "./topperformingcourses.scss";
import ChartTopPerformingCourses from "./chart/ChartTopPerformingCourses";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";

const TopPerformingCourses = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${SERVER_URL}/user-analytics/email-counts-by-chapters`
      );
      setData(response.data);
    };
    fetchData();
  }, []);

  return (
    <div className="top-performing-courses-container">
      <h3>Top 5 Performance</h3>
      <div className="top-chart-container">
        {data.length > 0 ? (
          <ChartTopPerformingCourses data={data} />
        ) : (
          <p>No data is available!</p>
        )}
      </div>
    </div>
  );
};

export default TopPerformingCourses;
