import React from "react";
import "./monthlyprogress.scss";
import ChartMonthlyProgress from "./chart/ChartMonthlyProgress";

const MonthlyProgress = () => {
  const areaChartData = [
    {
      name: "Oct23",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Nov23",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Dec23",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Jan24",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Feb24",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Mar24",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Apr24",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  return (
    <div className="monthly-progress-container">
      <h3>Monthly Progress</h3>
      <div className="monthly-progress-chart-container">
        <ChartMonthlyProgress data={areaChartData} />
      </div>
    </div>
  );
};

export default MonthlyProgress;
