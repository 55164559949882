// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popular-course-details {
  flex: 1 1;
  display: flex;
  align-items: center;
  gap: 5px;
  padding-left: 20px;
}
.popular-course-details .course-details {
  display: flex;
  flex-direction: column;
  gap: 0;
}
.popular-course-details .course-details p {
  margin-bottom: 0;
  color: #2D8BBA;
  font-weight: 700;
}
.popular-course-details .course-details span {
  font-size: 12px;
  color: #737373;
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/popularcoursesdetails.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;EACA,kBAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,MAAA;AACJ;AACI;EACE,gBAAA;EACA,cAAA;EACA,gBAAA;AACN;AAEI;EACE,eAAA;EACA,cAAA;EACA,gBAAA;AAAN","sourcesContent":[".popular-course-details {\n  flex: 1;\n  display: flex;\n  align-items: center;\n  gap: 5px;\n  padding-left: 20px;\n\n  .course-details {\n    display: flex;\n    flex-direction: column;\n    gap: 0;\n    \n    p {\n      margin-bottom: 0;\n      color: #2D8BBA;\n      font-weight: 700;\n    }\n\n    span {\n      font-size: 12px;\n      color: #737373;\n      margin-bottom: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
