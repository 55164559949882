import React from 'react';
import './privacypolicy.scss'

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <div className="privacy-top">
        <div className="privacy-heading">
          <h1>
            <i className="bi bi-hand-index-thumb"></i>Privacy Policy
          </h1>
          <span>Privacy Policy</span>
        </div>
        <div className="privacy-action">
          <div className="privacy-btn">
            <div className="icon">
              <i className="bi bi-plus-lg"></i>
            </div>
            <span>Add Policy</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy