// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-performing-courses-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1 1;
  height: 400px;
  padding: 20px;
}
.top-performing-courses-container h3 {
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0;
}
.top-performing-courses-container .top-chart-container {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/topperformingcourses.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;EACA,SAAA;EACA,aAAA;EACA,aAAA;AACJ;AACI;EACI,eAAA;EACA,gBAAA;EACA,yBAAA;EACA,gBAAA;AACR;AAEI;EACI,WAAA;EACA,YAAA;AAAR","sourcesContent":[".top-performing-courses-container{\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    flex: 1;\n    height: 400px;\n    padding: 20px;\n\n    h3{\n        font-size: 20px;\n        font-weight: 700;\n        text-transform: uppercase;\n        margin-bottom: 0;\n    }\n\n    .top-chart-container{\n        width: 100%;\n        height: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
