import React from "react";
import "./overallpasspercentage.scss";
import ChartOverallPassPercentage from "./chart/ChartOverallPassPercentage";

const OverallPassPercentage = () => {

  const pieChartData = [
    {
      id: "Nursing",
      label: "Nursing",
      value: 565,
      color: "hsl(219, 70%, 50%)",
    },
    {
      id: "Cyber Security",
      label: "Cyber Security",
      value: 495,
      color: "hsl(342, 70%, 50%)",
    },
    {
      id: "Cosmetology",
      label: "Cosmetology",
      value: 431,
      color: "hsl(290, 70%, 50%)",
    },
    {
      id: "Soft Skills",
      label: "Soft Skills",
      value: 493,
      color: "hsl(141, 70%, 50%)",
    },
    {
      id: "Dental",
      label: "Dental",
      value: 35,
      color: "hsl(147, 70%, 50%)",
    },
  ];

  return (
    <div className="overall-pass-percentage">
      <h3>Overall Pass Percentage</h3>
      <div className="overall-pass-percentage-chart-container">
        <ChartOverallPassPercentage data={pieChartData} />
      </div>
    </div>
  );
};

export default OverallPassPercentage;
