import React from "react";
import "./contentusage.scss";
import ChartContentUsage from "./chart/ChartContentUsage";

const ContentUsage = () => {
  const raderChartData = [
    {
      taste: "Immersion",
      nursing: 24,
      cyber_security: 42,
      cosmetology: 71,
    },
    {
      taste: "Personalization",
      nursing: 81,
      cyber_security: 60,
      cosmetology: 69,
    },
    {
      taste: "Gamification",
      nursing: 90,
      cyber_security: 83,
      cosmetology: 31,
    },
    {
      taste: "Ease of Use",
      nursing: 79,
      cyber_security: 33,
      cosmetology: 98,
    },
  ];

  return (
    <div className="content-usage-container">
      <h3>Content Usage</h3>
      <div className="content-usage-chart-container">
        <ChartContentUsage data={raderChartData}/>
      </div>
    </div>
  );
};

export default ContentUsage;
