import React from "react";
import "./popularcoursesdetails.scss";

const PopularCoursesDetails = ({ courseName, chapters }) => {
  return (
    <div className="popular-course-details">
      <div className="course-details">
        <p>{courseName}</p>
        <span>{chapters.length} chapters</span>
      </div>
    </div>
  );
};

export default PopularCoursesDetails;
