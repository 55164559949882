import React from 'react';
import { ResponsiveRadar } from "@nivo/radar";

const ChartContentUsage = ({data}) => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ResponsiveRadar
        data={data}
        keys={["nursing", "cyber_security", "cosmetology"]}
        indexBy="taste"
        valueFormat=">-.2f"
        margin={{ top: 70, right: 80, bottom: 40, left: 100 }}
        borderColor={{ from: "color" }}
        gridLabelOffset={36}
        dotSize={10}
        dotColor={{ theme: "background" }}
        dotBorderWidth={2}
        colors={{ scheme: "nivo" }}
        blendMode="multiply"
        motionConfig="wobbly"
        legends={[
          {
            anchor: "top-left",
            direction: "column",
            translateX: -50,
            translateY: -40,
            itemWidth: 80,
            itemHeight: 20,
            itemTextColor: "#999",
            symbolSize: 12,
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemTextColor: "#000",
                },
              },
            ],
          },
        ]}
        role="application"
        ariaLabel="Nivo radar chart demo"
      />
    </div>
  );
}

export default ChartContentUsage