import React, { useEffect, useState } from "react";
import "./usermanagement.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import UserDetails from "../../components/user-management/UserDetails";
import SearchBar from "../../components/searchbar/SearchBar";

const UserManagement = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchUser();
    setName("");
    setRole("student");
    setPassword("");
  }, []);

  const fetchUser = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await axios.get(`${SERVER_URL}/admin/list`);
      setData(response.data);
      setFilteredData(response.data);
    } catch (error) {
      setError("Error loading data!");
    } finally {
      setLoading(false);
    }
  };

  const filterData = (searchTerm) => {
    const filtered = data.filter((item) =>
      item.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleSearch = (searchTerm) => {
    filterData(searchTerm);
  };

  const handleAddClick = () => {
    setShowPopup(true);
  };

  const handleClose = () => {
    setShowPopup(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    try {
      const postData = {
        name,
        role,
        email,
        password,
      };

      const response = await axios.post(
        `${SERVER_URL}/admin/register`,
        postData
      );

      if (response.status === 200 || response.status === 201) {
        setData([...data, response.data]);
        setFilteredData([...data, response.data]);
        setEmail("");
        setShowPopup(false);
      }
    } catch (error) {
      setError("Error adding user!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="user-management-container">
      <div className="user-top">
        <div className="user-heading">
          <h1>
            <i className="bi bi-person"></i>User Management
          </h1>
          <span>User Management</span>
        </div>
        <div className="user-action">
          <SearchBar onSearch={handleSearch} />
          <div className="user-btn" onClick={handleAddClick}>
            <div className="icon">
              <i className="bi bi-plus-lg"></i>
            </div>
            <span>Add User</span>
          </div>
        </div>
      </div>
      <div className="user-details">
        <div className="user-details-top">
          <div className="user-details-top-sl">
            <span className="span-sl">SL No.</span>
          </div>
          <div className="user-details-top-email">
            <span className="span-email">Email</span>
          </div>
          <div className="user-details-top-role">
            <span className="span-role">Role</span>
          </div>
          <div className="user-details-top-action">
            <span className="span-action">Action</span>
          </div>
        </div>
        <div className="user-details-container">
          {loading && <p>Loading...</p>}
          {error && <p className="error">{error}</p>}
          {filteredData.map((row, index) => (
            <UserDetails
              row={row}
              index={index}
              key={index}
              refreshData={fetchUser}
            />
          ))}
        </div>
      </div>

      {showPopup && (
        <div className="popup">
          <div className="popup-inner">
            <button className="close-button" onClick={handleClose}>
              &times;
            </button>
            <div className="user-popup-container">
              <h2>Add User</h2>
              <form method="POST" onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <label htmlFor="email">Email</label>
                </div>
                <div className="form-group">
                  <select
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <option value="admin">Admin</option>
                    <option value="professor">Professor</option>
                    <option value="student">Student</option>
                  </select>
                  <label htmlFor="email">Role</label>
                </div>
                <div className="form-actions">
                  <button type="submit" disabled={loading}>
                    {loading ? "Saving..." : "Save"}
                  </button>
                </div>
              </form>
            </div>
            {error && <p className="error">{error}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserManagement;
