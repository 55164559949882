import React from "react";
import "./bestinstructordetails.scss";

const BestInstructorDetails = ({ item }) => {
  return (
    <div className="best-instructor-details">
      <div className="instructor-icon">
        <i className="bi bi-person-fill"></i>
      </div>
      <div className="instructor-details">
        <p>{item.name}</p>
        <span>{item.designation}</span>
      </div>
    </div>
  );
};

export default BestInstructorDetails;
