import React from "react";
import { ResponsiveBar } from "@nivo/bar";

const trimCourseNames = (data) => {
  return data.map((item) => ({
    ...item,
    course: item.course.slice(0, 4),
  }));
};

const ChartTopPerformingCourses = ({ data }) => {
  const trimmedData = trimCourseNames(data);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ResponsiveBar
        data={trimmedData}
        keys={["chapter1", "chapter2", "chapter3", "chapter4", "chapter5"]}
        indexBy="course"
        margin={{ top: 50, right: 30, bottom: 50, left: 40 }}
        padding={0.3}
        layout="horizontal"
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={(d) => d.data[`${d.id}Color`]}
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: "middle",
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: "middle",
          legendOffset: -40,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={(e) =>
          `${e.id}: ${e.formattedValue} in course: ${e.indexValue}`
        }
      />
    </div>
  );
};

export default ChartTopPerformingCourses;
