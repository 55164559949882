// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forum-in-discussion-container {
  flex: 1 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.forum-in-discussion-container .icon {
  width: 60px;
  height: 60px;
}
.forum-in-discussion-container h3 {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0;
  border-radius: 20px;
  background: linear-gradient(90deg, #0000ff, #ff22e6);
  color: #fff;
  padding: 5px 25px;
}
.forum-in-discussion-container p {
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/forumindiscussion.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,4CAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;AACJ;AAEE;EACE,eAAA;EACA,gBAAA;EACA,yBAAA;EACA,gBAAA;EACA,mBAAA;EACA,oDAAA;EACA,WAAA;EACA,iBAAA;AAAJ;AAGE;EACE,gBAAA;AADJ","sourcesContent":[".forum-in-discussion-container {\n  flex: 1;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 10px;\n  background: #fff;\n  padding: 20px;\n  border-radius: 10px;\n  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;\n\n  .icon {\n    width: 60px;\n    height: 60px;\n  }\n\n  h3 {\n    font-size: 18px;\n    font-weight: 700;\n    text-transform: uppercase;\n    margin-bottom: 0;\n    border-radius: 20px;\n    background: linear-gradient(90deg, #0000ff, #ff22e6);\n    color: #fff;\n    padding: 5px 25px;\n  }\n\n  p {\n    margin-bottom: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
