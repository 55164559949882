import React, { useEffect, useState } from 'react';
import './forumindiscussion.scss';
import { GrGroup } from "react-icons/gr";

const ForumInDiscussion = () => {
  const [forum, setForum] = useState(0);

  useEffect(() => {
    setForum(25);
  }, []);

  return (
    <div className="forum-in-discussion-container">
      <GrGroup className="icon" />
      <h3>Forum in discussion</h3>
      <p>{forum}+</p>
    </div>
  );
}

export default ForumInDiscussion