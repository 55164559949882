import React, { useEffect, useState } from "react";
import "./popularcourses.scss";
import { GiBookmark } from "react-icons/gi";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import PopularCoursesDetails from "./PopularCoursesDetails";

const PopularCourses = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/course/list`);
        setData(response.data);
      } catch (error) {
        console.error("Unable to retrieve courses!", console.error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="popular-courses-container">
      <h3>Popular Courses</h3>
      <GiBookmark className="icon" />
      <div className="popular-courses-details-container">
        {data.map((item, index) => (
          <PopularCoursesDetails
            key={index}
            courseName={item.courseName}
            chapters={item.chapters}
          />
        ))}
      </div>
    </div>
  );
};

export default PopularCourses;
