import React, { useEffect, useState } from "react";
import "./dashboard.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import Mentors from "../../components/dashboard/Mentors";
import Students from "../../components/dashboard/Students";
import CourseInProgress from "../../components/dashboard/CourseInProgress";
import ForumInDiscussion from "../../components/dashboard/ForumInDiscussion";
import TopPerformingCourses from "../../components/dashboard/TopPerformingCourses";
import BestInstructors from "../../components/dashboard/BestInstructors";
import PopularCourses from "../../components/dashboard/PopularCourses";
import ContentUsage from "../../components/dashboard/ContentUsage";
import MonthlyProgress from "../../components/dashboard/MonthlyProgress";
import OverallPassPercentage from "../../components/dashboard/OverallPassPercentage";

const Dashboard = () => {
  const [mentors, setMentors] = useState([]);
  const [students, setStudents] = useState([]);
  const [totalCourse, setTotalCourse] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [professorsResponse, studentsResponse, courseResponse] =
          await Promise.all([
            axios.get(`${SERVER_URL}/admin/count/professors`),
            axios.get(`${SERVER_URL}/admin/count/students`),
            axios.get(`${SERVER_URL}/user-analytics/count-distinct-courses`),
          ]);
        setMentors(professorsResponse.data.totalProfessors);
        setStudents(studentsResponse.data.totalStudents);
        setTotalCourse(courseResponse.data.distinctCourses);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="dashboard-container">
      <div className="dashboard-top">
        <div className="left-group">
          <div className="heading-group">
            <h1>
              Learn effectively
              <br />
              with us!
            </h1>
            <p>Get 30% off on every courses from June!</p>
          </div>
          <div className="group-container">
            <Mentors mentors={mentors} />
            <Students students={students} />
          </div>
        </div>
        <hr className="divider" />
        <div className="right-group">
          <div className="heading-group">
            <h1>
              Have more knowledge to
              <br />
              share!
            </h1>
          </div>
          <div className="group-container">
            <CourseInProgress totalCourse={totalCourse} />
            <ForumInDiscussion />
          </div>
        </div>
      </div>
      <div className="dashboard-middle">
        <TopPerformingCourses />
        <BestInstructors />
        <PopularCourses />
      </div>
      <div className="dashboard-bottom">
        <MonthlyProgress />
        <ContentUsage />
        <OverallPassPercentage />
      </div>
    </div>
  );
};

export default Dashboard;
