// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.best-instructor-details {
  flex: 1 1;
  display: flex;
  align-items: center;
  gap: 5px;
  padding-left: 20px;
}
.best-instructor-details .instructor-icon {
  font-size: 40px;
  color: #737373;
}
.best-instructor-details .instructor-details {
  display: flex;
  flex-direction: column;
  gap: 0;
}
.best-instructor-details .instructor-details p {
  margin-bottom: 0;
  color: #737373;
  font-weight: 700;
}
.best-instructor-details .instructor-details span {
  font-size: 8px;
  color: #737373;
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/bestinstructordetails.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;EACA,kBAAA;AACF;AACE;EACE,eAAA;EACA,cAAA;AACJ;AAEE;EACE,aAAA;EACA,sBAAA;EACA,MAAA;AAAJ;AAEI;EACE,gBAAA;EACA,cAAA;EACA,gBAAA;AAAN;AAGI;EACE,cAAA;EACA,cAAA;EACA,gBAAA;AADN","sourcesContent":[".best-instructor-details {\n  flex: 1;\n  display: flex;\n  align-items: center;\n  gap: 5px;\n  padding-left: 20px;\n\n  .instructor-icon {\n    font-size: 40px;\n    color: #737373;\n  }\n\n  .instructor-details {\n    display: flex;\n    flex-direction: column;\n    gap: 0;\n    \n    p {\n      margin-bottom: 0;\n      color: #737373;\n      font-weight: 700;\n    }\n\n    span {\n      font-size: 8px;\n      color: #737373;\n      margin-bottom: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
