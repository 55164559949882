import React, { useState, useEffect } from "react";
import "./bestinstructors.scss";
import { GiTeacher } from "react-icons/gi";
import BestInstructorDetails from "./BestInstructorDetails";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";

const BestInstructors = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/admin/list/professors`);
        setData(response.data);
      } catch (error) {
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="best-instructor-container">
      <h3>Best Instructors</h3>
      <GiTeacher className="icon" />
      <div className="best-instructor-details-container">
        {data.length > 0 ? (
          data.map((item, index) => (
            <BestInstructorDetails key={index} item={item} />
          ))
        ) : (
          <p>No data is available!</p>
        )}
      </div>
    </div>
  );
};

export default BestInstructors;
