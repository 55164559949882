import React, { useState } from "react";
import "./userdetails.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";

const UserDetails = ({ row, index, refreshData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [role, setRole] = useState("");
  const [editedEmail, setEditedEmail] = useState("");

  const handleEditClick = () => {
    if (row.email) {
      setModalMode("edit");
      setEditedEmail(row.email);
      setRole(row.role);
      setIsModalOpen(true);
    }
  };

  const handleDeleteClick = () => {
    if (row.email) {
      setIsDeleteConfirmOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditedEmail("");
  };

  const handleCloseDeleteConfirm = () => {
    setIsDeleteConfirmOpen(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        name: row.name,
        role: role,
        email: editedEmail,
      };
      let response;
      response = await axios.put(
        `${SERVER_URL}/admin/${row._id}/update`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        handleCloseModal();
        refreshData();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await axios.delete(
        `${SERVER_URL}/admin/${row._id}/delete`
      );
      if (response.status === 200) {
        console.log("Delete confirmed");
        refreshData();
      }
      handleCloseDeleteConfirm();
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div className="user-details-row" key={row._id}>
      <div className="user-details-row-sl">
        <span>{index + 1}.</span>
      </div>
      <div className="user-details-row-email">
        <span>{row.email}</span>
      </div>
      <div className="user-details-row-role">
        <span>{row.role}</span>
      </div>
      <div className="user-details-row-action">
        <div className="user-row-button-wrapper">
          <div className="button" onClick={handleEditClick}>
            <div className="icon">
              <i className="bi bi-pencil-square"></i>
            </div>
            <span>Edit</span>
          </div>
          <div className="button" onClick={handleDeleteClick}>
            <div className="icon">
              <i className="bi bi-trash"></i>
            </div>
            <span>Delete</span>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="popup">
          <div className="popup-content">
            <span className="close" onClick={handleCloseModal}>
              &times;
            </span>
            <div className="edit-user-popup-container">
              <h2>Edit Student</h2>
              <form onSubmit={handleFormSubmit}>
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={editedEmail}
                    onChange={(e) => setEditedEmail(e.target.value)}
                    required
                  />
                  <label htmlFor="email">Email</label>
                </div>
                <div className="form-group">
                  <select
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <option value="admin">Admin</option>
                    <option value="professor">Professor</option>
                    <option value="student">Student</option>
                  </select>
                  <label htmlFor="email">Email</label>
                </div>
                <div className="form-actions">
                  <button type="submit">
                    {modalMode === "add" ? "Add" : "Update"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {isDeleteConfirmOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseDeleteConfirm}>
              &times;
            </span>
            <h1>Confirm Delete</h1>
            <p>Are you sure you want to delete this user?</p>
            <div className="delete-buttons">
              <button onClick={handleDeleteConfirm}>Yes</button>
              <button onClick={handleCloseDeleteConfirm}>No</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDetails;
